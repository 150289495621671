@charset "UTF-8";
@font-face {
  font-family: Akkurat;
  src: url("../fonts/Akkurat-Regular.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: bold;
  src: url("../fonts/Akkurat-Bold.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-style: italic;
  src: url("../fonts/Akkurat-Italic.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: bold;
  font-style: italic;
  src: url("../fonts/Akkurat-Bold-Italic.otf") format("opentype"); }

@font-face {
  font-family: Akkurat Light;
  font-weight: 300;
  src: url("../fonts/Akkurat-Light-Regular.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Akkurat-Light-Italic.otf") format("opentype"); }

h1, h2, h3, h4, h5, h6 {
  font-family: Akkurat Light, "Helvetica Neue", Helvetica, sans-serif;
  color: #35237f; }

h2 {
  margin: 1em 0; }

body {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 100%;
  /* 16px */
  color: #333;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4; }

sup {
  font-size: 0.4em; }

.logo {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .logo h1 {
    margin: 0;
    font-size: 2em; }
  .logo .image {
    margin: 1em;
    width: 3em; }

.highlightOrange {
  width: 2em;
  margin-left: auto;
  margin-right: auto;
  padding: 0.4em 0.1em 0.1em 0.2em;
  background-color: #ed7d31; }

.disclaimer-tick {
  margin-top: 0.2em;
  padding: 1em 0.5em 2em 1em; }

.speed-test-app {
  display: flex;
  flex-direction: column;
  margin: 0 6em; }

.speed-test-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 1em; }
  .speed-test-container h2 {
    font-size: 2em;
    background-color: #FFFFFF;
    margin: 0;
    padding-top: 1em; }
  .speed-test-container .speed-test {
    width: 100%;
    height: 36em; }

button {
  cursor: pointer;
  color: white;
  background-color: #2d86ca;
  border: none;
  padding: 1em;
  font-size: 1.2em; }

button:focus {
  outline: none; }

.results {
  margin: 0 4em; }
  .results button {
    margin-right: 1em; }

.speed-results-reason button:after {
  color: white;
  margin-left: 4em;
  content: '›'; }

.troubleshooting-tool-banner {
  background-color: #e1eef8;
  padding: 2em; }

table {
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0; }

table thead th {
  text-align: left;
  padding-right: 2em; }

.table-wrap .table-responsive, .table-wrap .table {
  margin-bottom: 0; }

.table th, .table td {
  border: 1px solid #f4f4f4 !important;
  padding: 1em; }

.table-with-column-header {
  border: none !important; }
  .table-with-column-header thead tr:first-child > th {
    background-color: #9e9e9e !important;
    color: #fff !important;
    font-size: 1.16em; }
  .table-with-column-header thead .nbn-fast {
    background-color: #E8398D;
    color: #FFFFFF; }
  .table-with-column-header thead .nbn-very-fast {
    background-color: #C31B96;
    color: #FFFFFF; }
  .table-with-column-header thead .nbn-super-fast {
    background-color: #841F8D;
    color: #FFFFFF; }
  .table-with-column-header tr > th:first-child {
    background-color: #eee !important;
    text-align: left; }
  .table-with-column-header tbody td {
    color: #31237c; }

.results-disclaimer-container {
  display: flex;
  width: 100%;
  justify-content: space-around; }

.results-description {
  width: 60%; }

.results-disclaimer {
  display: flex;
  font-size: 0.8em;
  width: 40%;
  height: 2em; }

.disclaimer-content {
  margin-left: 4px; }

.table-speed thead tr th:nth-child(odd) {
  background-color: #d8d8d8 !important; }

.table-speed thead tr th:nth-child(even) {
  background-color: #eee !important; }

.table-speed thead tr th:nth-child(n+2) {
  text-align: center; }

.table-speed thead tr th.selected {
  background-color: #fff !important;
  border-left: 2px solid #31237c !important;
  border-right: 2px solid #31237c !important; }

.table-speed tbody tr td:nth-child(n+2) {
  text-align: center; }

.table-speed tbody tr td:nth-child(odd) {
  background-color: #d8d8d8 !important; }

.table-speed tbody tr td:nth-child(even) {
  background-color: #eee !important; }

.table-speed tbody tr td {
  padding: 0.5em 1em; }
  .table-speed tbody tr td.selected {
    background-color: #fff !important;
    border-left: 2px solid #31237c !important;
    border-right: 2px solid #31237c !important; }

.instructions {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .instructions h3 {
    color: #333;
    text-align: center;
    font-size: 1.3em;
    margin: 0; }
  .instructions .steps {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 2em; }
    .instructions .steps .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 16em; }
      .instructions .steps .step h3 {
        height: 3em; }
      .instructions .steps .step .number {
        font-size: 1.3em; }
      .instructions .steps .step .icon {
        margin: 2em 0;
        height: 64px; }
      .instructions .steps .step .description {
        text-align: center;
        width: 16em; }

.disclaimer {
  font-size: 0.8em; }

@media only screen and (max-width: 768px) {
  body {
    font-size: 75%;
    /* 12px */ }
  .speed-test-app {
    margin: 0; }
  .results {
    margin: 0; }
  .speed-test-container .speed-test {
    height: 48em; }
  .table th, .table td, .table-speed th, .table-speed td {
    font-size: 0.8em;
    padding: 0.3em; }
  .table-speed tbody tr td {
    padding: 0.3em; }
    .table-speed tbody tr td svg {
      width: 1em; }
  .instructions .steps {
    flex-direction: column; }
    .instructions .steps .step {
      width: inherit;
      margin-top: 2em; } }

.app-error {
  text-align: center; }
  .app-error .center-image {
    display: block;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto; }

@import "typography.scss";
@import "colors.scss";

$mobile-width: 768px;

h2 {
  margin: 1em 0;
}

body {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 100%; /* 16px */
  color: $text-color;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
}

sup {
  font-size: 0.4em;
}

.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    h1 {
      margin: 0;
      font-size: 2em;
    }
    .image {
      margin: 1em;
      width: 3em;
    }
  }

.highlightOrange {
  width: 2em;
  margin-left: auto;
  margin-right: auto;
  padding: 0.4em 0.1em 0.1em 0.2em;
  background-color: $highlight-orange;
}

.disclaimer-tick {
  margin-top: 0.2em;
  padding: 1em 0.5em 2em 1em;
}


.speed-test-app {
  display: flex;
  flex-direction: column;
  margin: 0 6em;
}

.speed-test-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 1em;
  h2 {
    font-size: 2em;
    background-color: $speed-test-background;
    margin: 0;
    padding-top: 1em;
  }
  .speed-test {
    width: 100%;
    height: 36em;
  }
}

button {
  cursor: pointer;
  color: white;
  background-color: $button-color;
  border: none;
  padding: 1em;
  font-size: 1.2em;
}

button:focus {
  outline: none;
}

.results {
  margin: 0 4em;
  button {
    margin-right: 1em;
  }
}

.speed-results-reason button:after {
  color: white;
  margin-left: 4em;
  content: '›';
}

.troubleshooting-tool-banner {
  background-color: $tool-background;
  padding: 2em;
}

table {
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

table thead th {
  text-align: left;
  padding-right: 2em;
}

.table-wrap .table-responsive, .table-wrap .table {
  margin-bottom: 0;
}

.table {
  th, td {
    border: 1px solid $table-border-color !important;
    padding: 1em;
  }
}

.table-with-column-header {
  border: none !important;
  thead {
    tr:first-child > th {
      background-color: $table-background-light !important;
      color: $table-title-color !important;
      font-size: 1.16em;
    }
    .nbn-fast {
      background-color: $table-background-fast;
      color: #FFFFFF;
    }
    .nbn-very-fast {
      background-color: $table-background-very-fast;
      color: #FFFFFF;
    }
    .nbn-super-fast {
      background-color: $table-background-super-fast;
      color: #FFFFFF;
    }
  }
  tr > th:first-child {
    background-color: $table-background-very-light !important;
    text-align: left;
  }
  tbody {
    td {
      color: $highlight-color;
    }
  }
}
.results-disclaimer-container{
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.results-description {
  width: 60%;
}

.results-disclaimer {
  display: flex;
  font-size: 0.8em;
  width: 40%;
  height: 2em;
}

.disclaimer-content {
  margin-left: 4px;
}

.table-speed {
  thead {
    tr {
      th:nth-child(odd) {
        background-color: $table-background-medium !important;
      }
      th:nth-child(even) {
        background-color: $table-background-very-light !important;
      }
      th:nth-child(n+2) {
        text-align: center;
      }
      th {
        &.selected {
          background-color: #fff !important;
          border-left: 2px solid $highlight-color !important;
          border-right: 2px solid $highlight-color !important;
        }
      }
    }
  }
  tbody {
    tr {
      td:nth-child(n+2) {
        text-align: center;
      }
      td:nth-child(odd) {
        background-color: $table-background-medium !important;
      }
      td:nth-child(even) {
        background-color: $table-background-very-light !important;
      }
      td {
        padding: 0.5em 1em;
        &.selected {
          background-color: #fff !important;
          border-left: 2px solid $highlight-color !important;
          border-right: 2px solid $highlight-color !important;
        }
      }
    }
  }
}

.instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    color: $text-color;
    text-align: center;
    font-size: 1.3em;
    margin: 0;
  }
  .steps {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 2em;
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 16em;
      h3 {
        height: 3em;
      }
      .number {
        font-size: 1.3em;
      }
      .icon {
        margin: 2em 0;
        height: 64px;
      }
      .description {
        text-align: center;
        width: 16em;
      }
    }
  }
}

.disclaimer {
  font-size: 0.8em;
}

@media only screen and (max-width: $mobile-width) {
  body {
    font-size: 75%; /* 12px */
  }

  .speed-test-app {
    margin: 0;
  }

  .results {
    margin: 0;
  }

  .speed-test-container .speed-test {
    height: 48em;
  }

  .table, .table-speed {
    th, td {
      font-size: 0.8em;
      padding: 0.3em;
    }
  }

  .table-speed tbody tr td {
    padding: 0.3em;
    svg {
      width: 1em;
    }
  }

  .instructions {
    .steps {
      flex-direction: column;
      .step {
        width: inherit;
        margin-top: 2em;
      }
    }
  }
}

.app-error {
  text-align: center;
  .center-image {
    display: block;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

$text-color: #333;
$speed-test-background: #FFFFFF;
$button-color: #2d86ca;
$tool-background: #e1eef8;
$table-border-color: #f4f4f4;
$table-background-light: #9e9e9e;
$table-background-dark: #7c7c7c;
$table-background-very-light: #eee;
$table-background-medium: #d8d8d8;
$table-title-color: #fff;
$table-background-fast:#E8398D;
$table-background-very-fast:#C31B96;
$table-background-super-fast:#841F8D;
$highlight-color: #31237c;
$highlight-orange: #ed7d31;

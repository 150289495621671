@font-face {
  font-family: Akkurat;
  src: url("../fonts/Akkurat-Regular.otf") format("opentype");
}

@font-face {
  font-family: Akkurat;
  font-weight: bold;
  src: url("../fonts/Akkurat-Bold.otf") format("opentype");
}

@font-face {
  font-family: Akkurat;
  font-style: italic;
  src: url("../fonts/Akkurat-Italic.otf") format("opentype");
}

@font-face {
  font-family: Akkurat;
  font-weight: bold;
  font-style: italic;
  src: url("../fonts/Akkurat-Bold-Italic.otf") format("opentype");
}

@font-face {
  font-family: Akkurat Light;
  font-weight: 300;
  src: url("../fonts/Akkurat-Light-Regular.otf") format("opentype");
}

@font-face {
  font-family: Akkurat;
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Akkurat-Light-Italic.otf") format("opentype");
}

h1, h2, h3, h4, h5, h6 {
  font-family: Akkurat Light, "Helvetica Neue", Helvetica, sans-serif;
  color: #35237f;
}
